import {useTranslate} from 'react-redux-multilingual'
import {
  ButtonBack,
  ButtonExtraLarge,
  ButtonCreateItem,
  ModalAddItemToList,
} from '../../../components'
import {BasketTable} from '../../../components/tables/BasketTable'
import {Link} from 'react-router-dom'

export const CreateBasket = (props) => {
  const t = useTranslate()
  const {
    userName,
    bannerMessage,
    listOfItems,
    productName,
    productBrand,
    productCount,
    productMeasurementUnit,
    productNote,
    modalOpen,
    isUpdating,
    handleCreateListItem,
    handleUpdateProductInList,
    handleProductName,
    handleProductBrand,
    handleProductCount,
    handleProductMeasurementUnit,
    handleEditProductNote,
    handleProductNote,
    handleRemoveItemFromList,
    handlePlaceOrder,
    handleModal,
  } = props.parameters

  return (
    <section className="basket">
      {bannerMessage ? <h6 className="banner-message">{bannerMessage}</h6> : null}
      {modalOpen ? (
        <ModalAddItemToList
          modalOpen={modalOpen}
          productName={productName}
          productBrand={productBrand}
          productCount={productCount}
          productMeasurementUnit={productMeasurementUnit}
          productNote={productNote}
          isUpdating={isUpdating}
          handleProductName={handleProductName}
          handleProductBrand={handleProductBrand}
          handleProductCount={handleProductCount}
          handleProductMeasurementUnit={handleProductMeasurementUnit}
          handleProductNote={handleProductNote}
          handleRemoveItemFromList={handleRemoveItemFromList}
          handlePlaceOrder={handlePlaceOrder}
          handleCreateListItem={handleCreateListItem}
          handleUpdateProductInList={handleUpdateProductInList}
          handleModal={handleModal}
        />
      ) : null}
      <div className="basket__top">
        <div className="basket__top__left">
          <ButtonBack />
          <h1 className="basket__top__left__user-name h4-bold ">{userName}</h1>
        </div>
      </div>
      <div className="layout__header">
        <h2 className="layout__header__label">{t('your_basket')}</h2>
      </div>
      <BasketTable
        listOfItems={listOfItems}
        handleEditProductNote={handleEditProductNote}
        handleRemoveItemFromList={handleRemoveItemFromList}
      />
      <div className="item__main__bottom">
        <ButtonCreateItem title={t('create_product')} handleClick={handleModal} />
      </div>
      {!userName ? (
        <div className="basket__warning-text">
          {t('warning_note_signup')}
          <br></br>
          <span className="line">
            <Link to="/">{t('sign_in')}</Link>
          </span>
        </div>
      ) : null}
      <ButtonExtraLarge
        type="submit"
        text={t('order_now')}
        handleClick={handlePlaceOrder}
        disabled={userName ? false : true}
      />
    </section>
  )
}
