var module = {
  es: {
    locale: 'es_ES',
    messages: {
      dashboard_header: 'La Tiendina - Mi bolsa de la compra a mi muelle!',
      are_you_sure: 'Estas seguro?',
      are_you_vendor: 'Eres vendedor?',
      accept_order: 'Aceptar pedido',
      accepted_order: 'El pedido ha sido aceptado',
      add: 'Añadir al carro',
      add_product: 'Añadir a la lista',
      add_remove: 'Añadir or Remove',
      already_registered: 'Ya estas registrado?',
      become_a_vendor: 'Hazte vendedor!',
      bill_details: 'Detalles de factura',
      buyer_id: 'Cliente id:',
      buyer_location: 'Direccion del cliente:',
      buyer_name: 'Nombre del cliente:',
      cancel: 'Cancelar',
      categories: 'Categorias',
      category_name: 'Nombre de la categoria:',
      category_parent_name: 'Nombre categoria padre:',
      category_path: 'Ruta de la categoria',
      cart: 'Carro de la compra',
      cancel_order: 'Cancelar pedido',
      confirm: 'Confirmar',
      confirm_password: 'Confirmar contrasena',
      continue: 'Continuar',
      count: 'Cantidad (Obligatorio)',
      close_order: 'Cerrar pedido',
      create_product: 'Crear producto',
      delete_product: 'Eliminar producto',
      delivery: 'Entrega a domicilio',
      delivered: 'has been successfully delivered',
      delivery_date_range_start: 'Entregar desde este dia',
      delivery_date_range_end: 'Entregar hasta este dia',
      delivery_date_time_start: 'Entregar desde esta hora',
      delivery_date_time_end: 'Entregar hasta esta hora',
      delivery_location: 'Lugar de entrega',
      delivery_note: 'Nota de entrega',
      delivery_status: 'Estado de entrega',
      description: 'Descripcion',
      error_message: 'Error en:',
      first_name: 'Nombre:',
      from_store: 'from the store',
      go_back: 'Atras',
      home: 'Inicio',
      home_delivery: 'Envio a casa',
      home_delivery_text: 'Pide toda tu compra a la vez y recogela en tu muelle',
      last_name: 'Apellido:',
      location: 'Direccion',
      logout: 'Cerrar sesion',
      measurement: 'pieza/lb/gl (Obligatorio)',
      measurement_placeholder: 'unidad/libra/galon',
      messages: 'Mensajes',
      mobile: 'Movil:',
      name: 'Nombre',
      need_an_account: 'Necesita una cuenta?',
      no_access: 'No tienes acceso a esta pagina.',
      no_items: 'No hay productos que mostrar',
      order: 'Pedido',
      orders: 'Pedidos',
      order_now: 'Pedir ahora',
      other_store_products: 'Otros productos para ti',
      order_status: 'Estado del pedido',
      password: 'Contrasena',
      price: 'Precio',
      price_single_unit: 'Precio de una sola unidad',
      price_total_units: 'Precio total de este producto',
      product: 'Producto',
      product_brand: 'Marca del producto (Opcional)',
      product_note: 'Nota del producto (Opcional)',
      products: 'Productos',
      product_created: 'Producto creado!',
      product_deleted: 'Product eliminado!',
      product_name: 'Nombre del producto (Obligatorio)',
      product_updated: 'Producto actualizado!',
      products_for_you: 'Productos para ti',
      pickup: 'Recoger en tienda',
      quantity: 'Cantidad',
      rate_transaction: 'Please, rate how the transaction was',
      reject_order: 'Rechazar pedido',
      resume: 'Reanudar',
      resume_order: 'Reanudar pedido',
      room_name: 'Nombre de la sala: ',
      store_dashboard: 'Trastienda',
      store_name: 'Nombre de la tienda',
      store_id: 'Tienda Id',
      store_location: 'Direccion de la tienda',
      search: '...Busca',
      search_result: 'Resultado de la busqueda para ',
      search_result_product: 'Resultado de la busqueda en productos',
      search_result_store: 'Resultado de la busqueda en tiendas',
      shipping: 'Envio',
      sign_in: 'Acceder',
      sign_up: 'Registrarse',
      submit: 'Enviar',
      subscribe: 'Subscribete y recibe notificaciones',
      unsubscribe: 'Cancelar subscripcion y notificaciones',
      success: 'Exito!',
      the: 'El',
      type: 'Tipo de tienda',
      update_store: 'Actualizar la tienda',
      update_product: 'Actualizar el producto',
      update_user: 'Actualizar usuario',
      updated_store: 'Actualizada la tienda',
      user_name: 'Nombre de usuario',
      unauthorized: 'No autorizado',
      you_can_open_your_own_store:
        'Tu puedes abrir tu propia tienda creando una cuenta pulsando el boton debajo de este texto',
      your_orders: 'Tus pedidos',
      your_products: 'Tus productos',
      your_basket: 'Tu cesta de la compra',
      warning_note_signup: 'Necesitas tener una cuenta antes de proceder con el pedido',
    },
  },
  en: {
    locale: 'en_US',
    messages: {
      dashboard_header: 'La Tiendina - My groceries at my dock',
      are_you_sure: 'Are you sure?',
      are_you_vendor: 'Are you a vendor?',
      accept_order: 'Accept Order',
      accepted_order: 'The order was accepted',
      add: 'Add to cart',
      add_product: 'Add to the list',
      add_remove: 'Add or Remove',
      already_registered: 'Already registered?',
      become_a_vendor: 'Become a vendor!',
      bill_details: 'Bill Details',
      buyer_id: 'Buyer id:',
      buyer_location: 'Buyer Location:',
      buyer_name: 'Buyer name:',
      cancel: 'Cancel',
      categories: 'Categories',
      category_name: 'Category Name:',
      category_parent_name: 'Category Parent Name:',
      category_path: 'Category path',
      cart: 'Cart',
      cancel_order: 'Cancel Order',
      create_product: 'Create Product',
      confirm: 'Confirm',
      confirm_password: 'Confirm Password',
      continue: 'Continue:',
      count: 'Quantity (Mandatory)',
      close_order: 'Close Order',
      delete_product: 'Delete product',
      delivered: 'has been successfully delivered',
      delivery: 'Delivery',
      delivery_date_range_start: 'Delivery date range start',
      delivery_date_range_end: 'Delivery date range end',
      delivery_date_time_start: 'Delivery time range start',
      delivery_date_time_end: 'Delivery time range end',
      delivery_location: 'Lugar de entrega',
      delivery_note: 'Delivery note',
      delivery_status: 'Delivery status',
      description: 'Description',
      error_message: 'Error in:',
      first_name: 'First Name:',
      from_store: 'from the store',
      go_back: 'Go Back',
      home: 'Home:',
      home_delivery: 'Home delivery',
      home_delivery_text:
        'Order your all your groceries at once and get them delivery at your dock.',
      last_name: 'Last Name:',
      location: 'Location',
      logout: 'Log out',
      measurement: 'unit/lb/gl (Mandatory)',
      measurement_placeholder: 'unit/pound/gallon',
      messages: 'Messages',
      mobile: 'Mobile:',
      name: 'Name',
      need_an_account: 'Need an account?',
      no_access: 'You do not have access to the requested page.',
      no_items: 'No items to show',
      order: 'Order',
      orders: 'Orders',
      order_now: 'Order Now',
      order_status: 'Order Status',
      other_store_products: 'Other Stores Products',
      password: 'Password',
      price: 'Price',
      price_single_unit: 'Price of single unit',
      price_total_units: 'Total price of this product',
      product: 'Product',
      product_brand: 'Product brand (Optional)',
      product_note: 'Product note (Optional)',
      products: 'Products',
      product_created: 'Product Created!',
      product_deleted: 'Product deleted successfully',
      product_name: 'Product Name (Mandatory)',
      product_updated: 'Product updated',
      products_for_you: 'Products for you',
      pickup: 'Pickup',
      quantity: 'Quantity (Mandatory)',
      rate_transaction: 'Please, rate how the transaction was',
      reject_order: 'Reject Order',
      resume: 'Resume',
      resume_order: 'Resume Order',
      room_name: 'Room Name: ',
      store_dashboard: 'Store Dashboard',
      store_name: 'Store Name',
      store_id: 'Store Id',
      store_location: 'Store location',
      search: '..Search',
      search_result: 'Search result for ',
      search_result_product: 'Search result in products',
      search_result_store: 'Search result in stores',
      shipping: 'Shipping',
      sign_in: 'Sign in',
      sign_up: 'Sign up',
      submit: 'Submit',
      subscribe: 'Subscribe and receive notifications!',
      unsubscribe: 'Unsubscribe and stop notifications',
      success: 'Success',
      the: 'The',
      type: 'Type of store',
      update_store: 'Update Store',
      updated_store: 'Updated Store',
      update_product: 'Update Product',
      update_user: 'Update User',
      user_name: 'User Name',
      unauthorized: 'Unauthorized',
      you_can_open_your_own_store:
        'You can open your own a store by clicking the button below',
      your_orders: 'Your Orders',
      your_products: 'Your Products',
      your_basket: 'Your Basket',
      warning_note_signup: 'You need to have an account to proceed ordering',
    },
  },
}

export default module
