import React from 'react'
import {useTranslate} from 'react-redux-multilingual'
import {BasketBanner} from '../../../components'
import {IconShop} from '../../../icons'

export const DashboardPublic = () => {
  const t = useTranslate()

  return (
    <div className="dashboard-public">
      <div className="dashboard-public__top-bar">
        <div className="dashboard-public__top-bar__top">
          <div className="logo">
            <IconShop />
            <h6 className="app-name">{t('dashboard_header')}</h6>
          </div>
        </div>
      </div>
      <div className="dashboard-public__main">
        <div className="dashboard-public__main__action">
          <BasketBanner />
        </div>
      </div>
    </div>
  )
}
