/* eslint-disable react/react-in-jsx-scope */
import {useState} from 'react'
import {IntlProvider, IntlActions} from 'react-redux-multilingual'
import {MenuBar} from './components'
import {RoutesPage} from './routes/Routes'
import translations from './constants/translations'
import {IconFlagEn, IconFlagSp} from './icons'
import store from './redux/store'

import './app.scss'

const App = () => {
  const [isEnglish, setIsEnglish] = useState(false)

  const handleClick = () => {
    setIsEnglish(!isEnglish)
    const language = isEnglish ? 'es' : 'en'
    store.dispatch(IntlActions.setLocale(language))
  }

  return (
    <div>
      <div className="language" onClick={handleClick}>
        <div className="language__icon">
          {isEnglish ? <IconFlagSp /> : <IconFlagEn />}
        </div>
      </div>
      <IntlProvider translations={translations} locale="es">
        <RoutesPage />
        <MenuBar />
      </IntlProvider>
    </div>
  )
}

export default App
